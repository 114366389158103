<template>
  <div class="design">

    <light-box :images="images" :index="index" @close="index = null" />

    <div class="page-title">
      <div class="content-container">
        <h1 class="title is-4">
          Design
        </h1>
      </div>
    </div>

    <div class="content-container page-text">
      <div class="columns">
        <div class="column">
          <p class="pad-bottom-20">
            We offer a complete in-house design team consisting of mechanical engineers and design draftsmen using the latest 2D and 3D design software.
          </p>

          <p class="pad-bottom-20">
            We work closely with our clients and offer professional design and detail of:
          </p>

          <div class="content">
            <ul>
              <li>Conceptual ideas</li>
              <li>Process & Instrument Diagrams</li>
              <li>Isometric Pipework layouts</li>
              <li>Structural Steel</li>
              <li>Tanks</li>
              <li>Hoppers</li>
              <li>Complete Factory Layouts</li>
              <li>3D Scanning – We offer 3D Scanning using a Leica RTC 360 Scanner</li>
            </ul>
          </div>
        </div>
        <div class="column is-5">
          <img :src="images[0]" width="100%" class="shadow pad pointer" @click="index = 0" />
        </div>
      </div>

      <img :src="images[1]" width="100%" class="shadow pad margin-top pointer" @click="index = 1" />

      <div class="columns is-multiline images margin-top">
        <div class="column is-4" @click="index = 2">
          <div class="image-container">
            <div class="image is-1by1"><img :src="images[2]" /></div>
          </div>
        </div>
        <div class="column is-4" @click="index = 3">
          <div class="image-container">
            <div class="image is-1by1"><img :src="images[3]" /></div>
          </div>
        </div>
        <div class="column is-4" @click="index = 4">
          <div class="image-container">
            <div class="image is-1by1"><img :src="images[4]" /></div>
          </div>
        </div>
      </div>

      <img :src="images[5]" width="100%" class="shadow pad margin-top pointer" @click="index = 5" />

    </div>

  </div>
</template>

<script>
export default {
  data () {
    return {
      index: null,
      images: [
        require('@/assets/img/design/small.png'),
        require('@/assets/img/design/large.png'),
        require('@/assets/img/design/sugar_hanger_3.jpg'),
        require('@/assets/img/design/sugar_hanger_1.png'),
        require('@/assets/img/design/sugar_hanger_2.jpg'),
        require('@/assets/img/design/platform.png')
      ]
    }
  }
}
</script>
